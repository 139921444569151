<template>
  <div class="text-left">

    <Header
              heading="Webhook"
            class="mb-4">
           <!-- <template #buttons>
            <div class="webhook-help-container d-flex align-items-center gap border-radius bg-white">
           <div class="webhook-help">
            
            <svg width="94" height="60" viewBox="0 0 94 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_541_2445)">
<rect width="94" height="60" rx="8" fill="white"/>
<rect x="4" y="4" width="26" height="66" rx="4" fill="#EEEEEF"/>
<rect x="34" y="4" width="26" height="66" rx="4" fill="#EEEEEF"/>
<rect x="64" y="4" width="26" height="66" rx="4" fill="#EEEEEF"/>
<path d="M29.1667 33.9998L20.8334 33.9998M29.1667 33.9998L25.8334 37.3332M29.1667 33.9998L25.8334 30.6665" stroke="#FEB44E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M59.1667 33.9998L50.8334 33.9998M59.1667 33.9998L55.8334 37.3332M59.1667 33.9998L55.8334 30.6665" stroke="#FEB44E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M89.1667 33.9998L80.8334 33.9998M89.1667 33.9998L85.8334 37.3332M89.1667 33.9998L85.8334 30.6665" stroke="#FEB44E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<rect x="0.5" y="0.5" width="93" height="59" rx="7.5" stroke="#EAEAEA"/>
<defs>
<clipPath id="clip0_541_2445">
<rect width="94" height="60" rx="8" fill="white"/>
</clipPath>
</defs>
</svg>

           </div>
           <div class="d-flex gap flex-column">
            <div class="help-heading">
              How Webhook Secter Works?
            </div>
            <div class="">            <a href="http://" target="_blank" class="help-url" rel="noopener noreferrer">
              Visit our help center <span class="ml-2"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.9463 7.0538L7.0537 12.9464M12.9463 7.0538V11.7678M12.9463 7.0538L8.23221 7.0538" stroke="#4D1B7E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</span>
            </a>
          </div>

           </div>
          </div>
           </template> -->
            </Header>
    <div class="card webhook-card border-radius border-color" >
      <!-- <div v-if="isAccountLoading" style="min-height:50vh;">
             <LoaderComp/>
             </div>  -->
             
      <div class="card-body"> 
         
        <div class="row">
          
<div class="col-12">
  <div class="d-flex align-items-center menu">
                        <router-link :to="{name:link.routerName}" v-for="link in links" :key="link.name">{{link.name}}</router-link>
                    </div>
</div>
          <div class="col-12 mt-3">
            
            <div >
              <transition name="route">
              <div class="" v-if="$route.name == 'Webhook'">
                 <div class="d-flex py-2 align-items-center" v-if="isWebhookLoading">
              <b-spinner small style="color: #4d1b7e"></b-spinner>
              <span class="ml-2">Loading Webhooks </span>
            </div>
            <div class="" style="min-height: 600px" v-if="isFirstTimeLoading">
              <b-skeleton-table
                :rows="5"
                :columns="3"
                :table-props="{ bordered: true }"></b-skeleton-table>
              <LoaderComp v-if="false" />
            </div>
                <b-table v-else-if="data.length"
                  class="webhook-table"
                  hover
                 
                  :items="data"
                  :fields="fields">
                  <template #cell(webhook_title)="data">
                   {{ data.item.webhook.event_title }}
                  </template>
                    <template #cell(actions)="data">
                    <b-dropdown dropright class="" id="collection-detail">
                  <template slot="button-content">
                    <div class="p-2">
                      <i class="text-dark fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </template>
                  <b-dropdown-item @click="$refs.newWebhook.OpenModal(true,data.item.id)" class="topbarLink d-flex align-items-center">
                   Edit </b-dropdown-item  
                  >
                 
                  
          
                     <b-dropdown-item v-b-modal.leadsBulkDelete @click="deleteWebhookId=data.item.id" class="topbarLink d-flex align-items-center" >
                    Delete </b-dropdown-item
                  >
                </b-dropdown>
                  </template>
                  <template #cell(quiz)="data">
                   {{data.item.quiz?data.item.quiz.title:'-'}}
                  </template>
                   <template #cell(success_ratio)="data">
                   <span>
                     <apexchart type="donut"  width="100" :options="chartOptions" :series="[data.item.success_ratio,(100-data.item.success_ratio)]"></apexchart>
                    
                   </span>
                  </template>
                  <template #cell(status)="data">
                    <b-spinner v-if="updateStatusId==data.item.id" small></b-spinner>
                    <b-form-checkbox
                    v-else
                      switch
                      v-model="data.item.status"
                      size="lg"
                      @change=changeStatus(data.item)
                      ></b-form-checkbox>
                  </template>
                </b-table>
                <template v-else>

                  <div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable @click="openCreateWebhook()" :isLoading="isCreateTokenLoading" :showButton="true" btnTitle="Fill my shelves">
  <template #title>
    No Webhook found
  </template>
  <template #description>
    Time to fill your shelves or refine your search. Remember, your results will appear right here.
  </template>
</EmptyTable>
</div>
           
          </template>
                   
            <QuizellPagination  :total-rows="totalRows" :currentPage="currentPage"
                  :per-page="perPage"   v-if="data.length" @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
              </div>
              <div class="" v-else-if="$route.name=='webhook-log'">
                <WebhookLogs ></WebhookLogs>
              </div>
               <div class="" v-else>
               
                     <WebhookSecret :propWebhookList="data"></WebhookSecret>
                 
                    </div>
                  </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
              <b-modal id="leadsBulkDelete" :title="`Delete webhook`">
                <p class="my-4">
                  Are you sure you want to delete this webhook? This can’t
                  be undone.
                </p>
                <template #modal-footer>
                  <div class="w-100 d-flex justify-content-end">
                    <b-button
                      :class="isLoadingDelete ? 'disabled' : ''"
                      variant="danger"
                      @click="DeleteWebhook()">
                      <span v-if="!isLoadingDelete"> Confirm </span>
                      <div class="d-flex align-items-center" v-else>
                         <div
                        class="spinner-border spinner-border-sm"
                        role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                        <span class="pl-2">Deleting...</span>
                      </div>
                     
                    </b-button>
                  </div>
                </template>
              </b-modal>
    <CreateNewWebhook  ref="newWebhook" @reload="GetAllWebhooks"></CreateNewWebhook>
    <FooterContent v-if="$route.name != 'webhook-secret'" >
<div class="d-flex align-items-center justify-content-end" >
  <AccountButton  @click="openCreateWebhook">+ Create New</AccountButton>

</div>
</FooterContent>
  </div>
</template>
<script>
import EmptyTable from "../Layout/EmptyTable.vue";
import { mapGetters , mapMutations } from "vuex";
import Vue from 'vue';
import WebhookLogs from "./WebhookLogs.vue";
// import Header from "../Layout/Heading.vue";
import CreateNewWebhook from "./CreateNewWebhook.vue";
import LoaderComp from "../../customize/component/LoaderComp.vue";
// import  from "./components/AccountButton.vue";
import AccountButton from "../Layout/Button.vue";
import Header from "../Layout/Header.vue";
import WebhookSecret from './WebhookSecret.vue';
import QuizellPagination from '../Layout/QuizellPagination.vue'
import FooterContent from '../Layout/FooterContent.vue';
export default {
  components: {
    LoaderComp,
    Header,
    EmptyTable,
    QuizellPagination,
    AccountButton,
    CreateNewWebhook,
    WebhookLogs,
    FooterContent,
WebhookSecret
  },

  data() {
    return {
      links:[{
        name:'Webhook List',
        routerName:'Webhook'
        },
        {
            name:'Webhook Logs',
            routerName:'webhook-log'
        },
        {
            name:'Webhook Simulator',
            routerName:'webhook-secret'
        },
        
    ],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        labels: ['Success', 'Error'],
        colors: ['#1abc9c', '#e74c3c'],
         dataLabels: {
          enabled: false // Hide data labels
        },
         legend: {
    show: false
  },
         tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value + '%'; // Add '%' to the value in the tooltip
            }
          }
        }
      },
      isFirstTimeLoading:true,
      updateStatusId:'',
      currentPage: 1,
      perPage: 10,
      totalRows: 20,
      isLoadingDelete:false,
      deleteWebhookId:'',
      activeTab: "Webhook",
      isCreateWebhookLoading: false,
      isWebhookLoading: false,
      isUpdateWebhookLoading: false,
      accessWebhooks: [],
      fields: [
        {
          key: "webhook_title",
          label: "Event",
           tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading'
        },
           {
          key: "quiz",
          label: "Quiz",
           tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading'
        },
        {
          key: "webhook_url",
          label: "URL",
           tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading'
        },
        {
          key: "success_ratio",
          label: "Success rate",
           tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading'
        },
        {
          key: "Status",
          label: "Status",
           tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading'
        },
        {
          key: "actions",
          label: "",
           tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading'
        },
      ],
      data: [
      
      ],
    };
  },
  computed: {
    ...mapGetters(["getPlanData"]),
    UserPlanData(){
      return this.getPlanData
    },
  },
  methods: {
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.GetAllWebhooks();
    },
    updatePagination(e){
      this.currentPage=e;
      this.GetAllWebhooks();
    },
        loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('src', src);
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    initApexCharts() {
      this.loadScript('https://cdn.jsdelivr.net/npm/apexcharts')
        .then(() => {
          return this.loadScript('https://cdn.jsdelivr.net/npm/vue-apexcharts');
        })
        .then(() => {
         

          // Registering ApexCharts as a global component
          Vue.component('apexchart', window.VueApexCharts);
          this.scriptsLoaded = true;
          return 
          
        })
        .catch(err => {
          throw err
        });
    },
    ...mapMutations(['setUpgradePlansModal']),
     getActiveBtn(pathName){
        return this.$route.name == pathName ? 'webhook-active':null
      },
    SpliceDate(WebhookDate) {
      if (WebhookDate) return WebhookDate.slice(0, 10);
    },
    openCreateWebhook(){

      let allowedPlan = ['Pro','Plus','Enterprise plan Special']
      if( allowedPlan.includes(this.UserPlanData.name) ){
        this.$refs.newWebhook.OpenModal()
      } else{
        let obj = {
            isOpen: true,
            
            title: "Upgrade to Access Webhooks Feature",
          subText: "Seamlessly connect your quiz results with third-party servers using our Webhook feature. By upgrading, you unlock the power to automate data transfer, enhancing efficiency and connectivity.",
          };

          this.setUpgradePlansModal(obj);
      }

      
     
    },
    async DeleteWebhook() {
      this.isLoadingDelete=true
     const response = await this.$store.dispatch('fetchData',{
        axiosMethod:'delete',
        resource:'webhooks',
        method:'delete',
        id:this.deleteWebhookId
      })
      if(response&&response.status=='success'){
        this.$bvModal.hide('leadsBulkDelete');
        this.toast('Webhook deleted successfully!')
        this.GetAllWebhooks();
      }
      else this.toast('Something went wrong!')
          this.isLoadingDelete=false
        },
    
  async changeStatus({id,status}){
this.updateStatusId=id
const response = await this.$store.dispatch('fetchData',{
  axiosMethod:'post',
  resource:'webhooks',
  method:'update',
  id:id,
  params:{status:status}
})
if(response&&response.status=='success')
{
  this.toast('Status Updated Successfully!')
  this.GetAllWebhooks();
}
else  this.toast('Something went wrong!')
this.updateStatusId=''
  },
    toast(text){
      {
          this.$toasted.show(text, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
    },
    async GetAllWebhooks() {
      this.isWebhookLoading = true;
      const response = await this.$store.dispatch('fetchData',{
        axiosMethod:'get',
        resource:'webhooks',
        method:'list',
        params:{params:{pages:this.currentPage,per_page:this.perPage}}
      })
      if(response && response.status=='success')
      {
      
        this.data=response.data.data
           this.totalRows = response.data.total;
      }
     this.isWebhookLoading = false;
     this.isFirstTimeLoading=false;
    },
    async copyWebhook(token) {
      await navigator.clipboard.writeText(token.access_token);
      this.$toasted.show("API Key Copied ", {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
    },
  },
  mounted() {
     this.initApexCharts();
      this.activeTab =this.$route.name
    this.GetAllWebhooks();
  },
};
</script>
<style>
.help-url,.help-url:hover{
  color: #4D1B7E;

font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
text-decoration: none;
border-bottom:1px solid #4D1B7E;
}
.webhook-help-container{
  display: flex;
padding: 8px 16px 8px 8px;
align-items: center;
background:#ffffff;
background-color:#FFF;
}
.help-heading{
  color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;

line-height: 20px; /* 142.857% */
letter-spacing: 0.56px;
text-transform: capitalize;
}
.route-enter-from {
opacity: 0;
transform: translateX(100px)
}
.route-enter-active {
transition: ass 0.3s ease-out;
}
.route-leave-to {
opacity: 0;
transform: translateX(-100px)}
.route-leave-active{
transition: all 0.3s ease-in;}
</style>
<style scoped>
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
.TitleHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;

  color: #18191c;
}
.EmptyKey {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 42px;
  text-align: center;
  color: #18191c;
}

.addWebhook {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  min-width: 170px;
  color: #ffffff;
}
.addWebhook:hover {
  color: #ffffff;
}
.webhook-table >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.webhook-table >>> thead tr {
  border-radius: 10px;
}
.webhook-table >>> thead th {
  border: none;
}
</style>
<style>

.table thead th {
  border-bottom: none !important;
  border-top: none !important;
}
.tab-item {
  cursor: pointer;
}
.webhook-active {
  position: relative;
  color: #4d1b7e;
  font-weight: bold;
}
.webhook-active:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background: #4d1b7e;
  bottom: -10px;
  left: 0px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}

.custom-control-input.radio:checked ~ .custom-control-label::before {
  background-color: #0397cc !important;
  color: #0397cc !important;
  border-color: #0397cc !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}
.routeLink:hover,.routeLink{
  text-decoration: none;
  color:inherit;
}
.menu{
    gap:20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 15px;
}
.menu a{
    color: #73738D;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
text-decoration: none;
position: relative;
}
.menu a.router-link-exact-active{
    color:var(--primary-color)
}
.menu a.router-link-exact-active:after{
    display: block;
    content: '';
    position: absolute;
    bottom: -15px;
    height: 2px;
    /* margin-bottom: 20px; */
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    background: var(--primary-color);
}

</style>
